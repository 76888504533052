import { Form } from '@deleteagency/forms';
import lodashGet from 'lodash/get';
import Deferred from 'general/js/deferred';
import { scrollObserver } from 'general/js/scroll-observer';
import api from '../api';

export default class BaseForm extends Form {
    constructor(el, options, nested) {
        options = {
            ...options,
            parsley: {
                errorClass: 'is-invalid',
                successClass: 'is-valid',
                errorsWrapper: '<ul class="form-control-errors" aria-live="assertive"></ul>',
                errorTemplate: '<li></li>',
                classHandler: (field) => field.$element.closest('[data-form-control]'),
                errorsContainer: (field) => field.$element.closest('[data-form-control]'),
            },
            axiosInstance: api,
            recaptchaSitekey: lodashGet(window.appConfig, 'GOOGLE_RECAPTCHA_SITE_KEY'),
            beforeSubmitPromise() {
                if (nested && nested.spinner) {
                    nested.spinner.show();
                }
                // const isLocalDev = window.location.hostname === 'localhost';

                // if (isLocalDev) {
                //     return Promise.resolve();
                // }

                return this._getCaptcha().then(([grecaptcha, captchaId]) => {
                    this.captchaIsReady = new Deferred();
                    if (typeof captchaId !== 'undefined') {
                        grecaptcha.execute(captchaId);
                        return this.captchaIsReady.promise;
                    }
                    return this.captchaIsReady.resolve();
                });
            },
            onAfterSubmit() {
                this._onAfterSubmit();
            },
        };
        super(el, options);
        this.nestedInstance = nested;
        this.element = el;
        this.withRecaptcha = options.withRecaptcha ?? true;
        this.recaptchaApplied = false;
        this.recaptchaSitekey = options.recaptchaSitekey;
        this._init();
    }

    _init() {
        this.unsubscribeScrollObserver = scrollObserver.subscribe(this.element, async () => {
            const { onReady } = await import(
                /* webpackChunkName: "recaptcha" */ './load-recaptcha'
            );

            this._initRecaptcha(onReady);
            this.recaptchaApplied = true;
            this.unsubscribeScrollObserver();
        });
    }

    _initRecaptcha(onReady) {
        if (this.recaptchaApplied) return;

        if (this.withRecaptcha && this.recaptchaSitekey) {
            this._captchaPromise = new Promise((resolve) => {
                const recaptchaPlaceholder = document.createElement('div');
                this.element.insertAdjacentElement('beforeEnd', recaptchaPlaceholder);
                onReady((grecaptcha) => {
                    const captchaId = grecaptcha.render(recaptchaPlaceholder, {
                        sitekey: this.recaptchaSitekey,
                        callback: () => {
                            this.captchaIsReady.resolve();
                        },
                        'error-callback': (error) => {
                            this.captchaIsReady.reject();
                        },
                        'expired-callback': (error) => {
                            this.captchaIsReady.reject();
                        },
                        badge: 'inline',
                        size: 'invisible',
                    });
                    resolve([grecaptcha, captchaId]);
                });
            });
        } else {
            this._captchaPromise = Promise.resolve([]);
        }
    }

    _getCaptcha() {
        return this._captchaPromise;
    }

    _onAfterSubmit = () => {
        if (this.nestedInstance.spinner) this.nestedInstance.spinner.hide();
        this.resetCaptcha();
    };

    resetCaptcha() {
        this._getCaptcha().then(([grecaptcha, captchaId]) => {
            if (typeof captchaId !== 'undefined') {
                grecaptcha.reset(captchaId);
            }
        });
    }

    // TODO update @deleteagency/forms
    extractValidationErrors(response = {}) {
        const data = response.data || response;
        let result = {};

        if (!data.succeeded) {
            if (data.invalidMembers) {
                result = data.invalidMembers;
            }

            if (data.errorMessage) {
                if (result) {
                    result._ = data.errorMessage;
                } else {
                    result = { _: data.errorMessage };
                }
            }
        }

        if (data.type === 'validationErrors') {
            result = data.errors;
        }
        return result;
    }

    onSuccessfulSubmit(response) {
        console.log(response);
    }

    onFailedSubmit(error) {
        console.error(error);
    }

    errorsSummaryTemplate(errors) {
        return `<div class="errors-summary" role="alert">${errors
            .map((error) => `<div>${error}</div>`)
            .join()}</div>`;
    }

    onError(err) {
        console.error(err);
    }
}
